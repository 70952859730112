/*
@font-face {
  font-family: 'FONTNAME';
  src: url('../fonts/FONTNAME.eot');
  src: url('../fonts/FONTNAME.eot?#iefix')format('embedded-opentype'),
    url('../fonts/FONTNAME.woff2') format('woff2'),
    url('../fonts/FONTNAME.woff') format('woff'),
    url('../fonts/FONTNAME.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
*/

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Semibold'), local('Graphik-LCG-Semibold'),
      // url('GraphikLCG-Semibold.woff2') format('woff2'),
      // url('GraphikLCG-Semibold.woff') format('woff'),
      url('../fonts/GraphikLCG-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Regular'), local('Graphik-LCG-Regular'),
      // url('GraphikLCG-Regular.woff2') format('woff2'),
      // url('GraphikLCG-Regular.woff') format('woff'),
      url('../fonts/GraphikLCG-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
