.modal-ok {
	display: none;
	position: fixed;
	bottom: 0;
	right: 0;
	width: 345px;
	z-index: 10;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.72;

  color: $darkGrey;
	padding: 24px 24px;
	// transform: translateY(120%) translateZ(0)
	// transition: 0.3s 0s ease-out
	bottom: -100%;
	transition: 0.8s 0s ease-out;
	@include respond-max('mobile') {
		padding: 16px;
		width: 100%;
  }
	&.modal-ok--ready {
		display: block;
  }
	&.modal-ok--show {
		// transform: none
		transition: 0.8s 0s ease-out;
		display: block;
		bottom: 0;
		.modal-ok__close {
			// transition: 0.3s 0.5s ease-out
			transform: none;
    }
  }
	&__box {
		position: relative;
		background-color: #fff;
		// border: 1px solid #E9E9E9;
		padding: 37px 37px 40px 32px;
    box-shadow: 0px 4px 8px rgba(0,0,0,.24);
		@include respond-max('mobile') {
      padding: 37px 50px 40px 24px;
    }
		@media screen and (max-width: 320px) {
      padding: 37px 34px 40px 24px;
    }
  }
	&__title {
    font-size: 18px;
    margin-bottom: 13px;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: $black;
  }
	&__close {
		display: block;
		cursor: pointer;
		user-select: none;
		position: absolute;
    top: 11px;
    right: 12px;
		padding: 7px;
		// transform: rotate(-90deg) translateZ(0)
		transition: 0.3s 0s ease-in;
		svg {
			display: block;
			stroke: #000;
			width: 12px;
			height: 12px;
    }
  }
}
