$black: #001426;
$blue: #005DAD;
.icon-logo-mobile {
  width: 150px;
  height: 32px;
  display: none;
  @include respond-max('tablet') {
    display: block;
  }
}
.menu {
  width: 0;
  height: 0;
  overflow: hidden;
  display: none;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 100;
  color: #fff;
  transition: background-color 0.25s 0s ease, color 0.25s 0s ease;
  // @include respond-max('tablet') {
  //   background-color: yellow;

  // }
  // @include respond-max('mobile') {
  //   background-color: bisque;
  // }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid rgba(255,255,255,.8);
    @include respond-max('tablet') {
      padding: 16px 0;
    }
    @include respond-max('tablet-small') {
      padding: 24px 0;
    }
    @include respond-max('mobile') {
      padding: 16px 0;
    }
  }
  &__col-4 {
    display: none;
    @include respond-max('tablet-small') {
      display: block;
    }
  }
  &__col-1 {
    margin-left: 2px;
    @include respond-max('mobile') {
      margin-left: 0;
    }
  }
  &__col-2 {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    padding-left: 65px;
    padding-right: 30px;
    @include respond-max('tablet') {
      padding-left: 30px;
      padding-right: 20px;
    }
    @include respond-max('tablet-small') {
      padding: 0;
      display: none;
    }
  }
  &__col-3 {
    display: flex;
    align-items: center;
    @include respond-max('tablet-small') {
      display: none;
    }
  }
  &__become-client-btn {
    @include respond-max('tablet') {
      font-size: 12px;
    }
  }
  &__logo-link {
      color: inherit;
      display: inline-block;
      vertical-align: middle;
      // transition: 0.25s ease;
  }
  &__link-submenu {
    position: absolute;
    top: 30px;
    left: 0;
    background: white;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);

    opacity: 0;
    pointer-events: none;
    transform: translateY(32px);
    transition: all .4s;

    & a {
      color: #001426;
      display: block;
      padding: 6px 16px;
      transition: background-color .3s;
      white-space: nowrap;
      cursor: pointer;
      line-height: 1.6;

      &:hover {
        background-color: rgba(0, 0, 0, .2);
      }
    }
  }
  &__link {
    display: inline-block;
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 2.5;
    text-decoration: none;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 32px;
      @include respond-max('tablet') {
        margin-right: 24px;
      }
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      margin-bottom: -37px;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: #005DAD;
      opacity: 0;
      transition: 0.25s ease;
      @include respond-max('tablet') {
        margin-bottom: -29px;
      }
    }
    // &.active {
    &:hover {
      &::before {
        left: 0;
        width: 100%;
        background-color: #005DAD;
        opacity: 0.8;
        transition: 0.25s ease;
      }

      & .header__link-submenu {
        opacity: 1;
        pointer-events: all;
        transform: none;
      }
    }
  }
  &__phone-wrapper {
    margin-right: 31px;
  }
  &.header_fixed {
    color: $black;
    background-color: #fff;
    .header__logo-link {
      color: $blue;
    }
    .burger {
      color: $blue;
    }
  }
  &.header--static {
    color: $black;
    background-color: #fff;
    position: relative;
    .header__logo-link {
      color: $blue;
    }
    .burger {
      color: $blue;
    }
  }
}
.icon-logo {
  width: 278px;
  height: 32px;
  display: block;
  // transition: 0.25s ease;
  @include respond-max('tablet') {
    display: none;
  }
}


.icon-burger {
  width: 24px;
  height: 24px;
}

.icon-close-burger {
  width: 24px;
  height: 24px;
}
.menu_open {
  .burger {
    .icon-burger {
      opacity: 0;
      transform: scale(0.01);
    }

    .icon-close-burger {
      opacity: 1;
      transform: scale(1);
    }
  }
}
.burger {
	display: flex;
	position: relative;
	color: #fff;

	svg {
		transition: transform .2s linear, opacity .1s ease;
	}

	.icon-close-burger {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		transform: scale(0.01);
		margin: auto;
	}
}


.menu {
  width: 100%;
  height: auto;
  background-color: #fff;
  z-index: 99;
  overflow: visible;
  display: block;
  position: fixed;
  right: -120%;
  top: 0;
  bottom: 0;
  transition: 0.3s 0s ease-in-out;
  &__scroll-wrapper {
    overflow-y: auto;
    max-height: 100%;
  }
  &__button-wrapper {
    padding-top: 88px;
    margin-bottom: 64px;
  }
  // &__container {
  //   padding: 0 24px;
  // }
  &__link-wrap {
    margin-bottom: 32px;
  }
  &__link {
    display: inline-block;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.35;
    letter-spacing: 0.01em;
    color: #001426;
  }
  &__link-submenu {
    text-indent: 10px;

    & a {
      margin-bottom: 4px;
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 24px;
    padding-top: 1px;
    background-color: #fff;
    .modal-footer__button.button--white {
      margin-top: 0;
    }
  }
}

.menu_open {
  .menu {
    @include respond-max('tablet-small') {
      right: 0%;
      transition: 0.6s 0s ease-in-out;
    }
  }
  .header {
    color: $black;
    background-color: #fff;
    .header__logo-link {
      color: $blue;
    }
    .burger {
      color: $blue;
    }
  }
  @include respond-max('tablet') {
    transition: 0.4s 0s ease-in-out;
  }
}
