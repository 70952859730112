.footer {
  font-size: 14px;
  background-color: #FAFAFA;
  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $darkGrey;
    border-top: 1px solid #ebebebcc;
    padding: 60px 0;
    @include respond-max('tablet') {
      padding: 45px 0;
    }
    @include respond-max('mobile') {
      padding: 36px 0;
      display: block;
    }
  }
  &__privacy-link {
    color: $blue;
    text-decoration: none;
    // &:hover {

    // }
  }
  &__copyright {
    @include respond-max('mobile') {
      margin-bottom: 16px;
    }
  }
}
