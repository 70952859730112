.projects {
  position: relative;
  padding-top: 104px;
  padding-bottom: 104px;

  @include respond-max('tablet-small') {
    padding-top: 84px;
    padding-bottom: 100px;
  }
  @include respond-max('mobile') {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &__top {
    background-image: url(../images/pattern.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 4px solid $blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 36px 61px;
    margin-bottom: 104px;
    @include respond-max('tablet-small') {
      padding: 26px 41px;
      margin-bottom: 84px;
    }
    @include respond-max('mobile') {
      padding: 28px 20px;
      margin-bottom: 24px;
      display: block;
    }
  }
  &__top-left {
    @include respond-max('mobile') {
      margin-bottom: 22px;
    }
  }
  &__doc-link {
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.34;
    letter-spacing: 0.01em;
    color: $blue;
    @media screen and (max-width: 320px) {
      font-size: 15px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      fill: none;
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 11px;
      @include respond-max('mobile') {
        margin-left: 12px;
      }
    }
  }
  &__title {
    font-weight: 600;
    font-size: 56px;
    line-height: 1.15;
    letter-spacing: 0.01em;
    color: $black;
    margin: 0;
    span {
      display: block;
      color: $blue;
    }
    @include respond-max('tablet') {
      font-size: 32px;
    }
    @include respond-max('mobile') {
      font-size: 24px;
      line-height: 1.35;
    }
  }
}
.icon-area {
  fill: none;
}


.project-item {
  position: relative;
  margin-bottom: 159px;
  @include respond-max('tablet') {
    margin-bottom: 130px;
  }
  @include respond-max('tablet-small') {
    margin-bottom: 80px;
  }
  @include respond-max('mobile') {
    margin-bottom: 42px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__info {
    // padding-top: 191px;
    padding-top: 147px;
    padding-bottom: 10px;
    @include respond-max('tablet') {
      padding-top: 35px;
    }
    @include respond-max('tablet-small') {
      padding-top: 0;
    }
  }
  &__location {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.11em;
    text-transform: uppercase;
    color: $darkGrey;
    margin-bottom: 40px;
    border-top: 1px solid $lightGrey;
    padding-top: 16px;

    @include respond-max('tablet-small') {
      padding-top: 12px;
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 25px;
    }
    @include respond-max('mobile') {
      border-top: 0;
      padding-top: 28px;
      margin-bottom: 12px;
      letter-spacing: 0.06em;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.375;
    letter-spacing: 0.01em;
    color: $black;
    margin-bottom: 24px;
    @include respond-max('tablet') {
      font-size: 32px;
    }
    @include respond-max('tablet-small') {
      font-size: 24px;
      margin-bottom: 14px;
    }
    @include respond-max('mobile') {
      margin-bottom: 15px;
    }
  }
  &__area {
    font-size: 14px;
    padding: 4px 0;
    margin-right: 24px;
    margin-bottom: 15px;
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &__detail-link-wrapper {
    padding-top: 28px;
    @include respond-max('mobile') {
      padding-top: 4px;
    }
  }
  &__detail-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: $blue;
    text-decoration: none;
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &__detail-icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
    .icon {
      width: 24px;
      height: 24px;
      display: block;
    }
  }
  &__area-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    .icon {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
  &__advantages {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__photo-wrap {
    padding-left: 31px;
    @include respond-max('tablet') {
      padding-left: 20px;
    }
    @include respond-max('tablet-small') {
      padding-left: 10px;
    }
    @include respond-max('mobile') {
      padding-left: 0;
      margin-left: -24px;
      margin-right: -24px;
    }
  }
  &__photo {
    width: 100%;
    padding-bottom: 65.7%;
    background-color: $darkGrey;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include respond-max('mobile') {
      padding-bottom: 66.8%;
    }
  }
  &__badge-row {
    position: absolute;
    top: 24px;
    right: 40px;
    display: flex;
    justify-content: flex-end;

    @include respond-max('mobile') {
      top: 16px;
      right: 8px;
    }
  }
  &__col-1 {
    @include respond-max('mobile') {
      order: 2;
    }
  }
  &__col-2 {
    @include respond-max('mobile') {
      order: 1;
    }
  }
}

.advant-item {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.34;
  color: $black;
  padding: 8px 16px 8px 12px;
  background: #FAFAFA;
  border-radius: 50px;
  margin: 0 -12px 15px;
  &__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    .icon {
      display: block;
      width: 16px;
      height: 16px;
      color: $darkGrey;
    }
  }
  &__name {
    display: inline-block;
    vertical-align: middle;
  }

}

.badge {
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: $black;
  background-color: #FFFFFF;
  padding: 4px 3px 4px 12px;
  border-radius: 20px;
  user-select: none;
  &__name {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    padding-top: 2px;
  }
  &__class {
    // display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    // line-height: 24px;
    font-weight: 400;
    text-align: center;
    background-color: $blue;
    color: #FFFFFF;
    min-width: 24px;
    border-radius: 12px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 24px;
    padding-top: 2px;
  }
}
