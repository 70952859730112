$blue: #005DAD;
$blueHover: #0073D6;

.button {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.01em;
	border: 0;
  border-radius: 4px;
	outline: none;
	text-decoration: none;
  user-select: none;
  background-color: $blue;
  padding: 17px 24px;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  transition: 0.25s ease;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: $blueHover;
    text-decoration: none;
  }
  // background-color: color('green', 'base');
  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 11px;
    height: 11px;
    margin-right: 0;
    margin-left: 18px;
  }
  &__text {
    width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
  }
  &.button_text-left {
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.style_white {
    background-color: #fff;
    color: $black;
  }
  &--white {
    width: 100%;
    background-color: #fff;
    color: $black;
    border: 1px solid #C1C6C8;
    // padding: 11px 23px;
    padding: 11px 15px 11px 23px;
    font-weight: 600;
    letter-spacing: -0.01em;
    .icon-phone {
      width: 24px;
      height: 24px;
      fill: none;
    }
    &:hover {
      background-color: #fff;
      color: $black;
    }
  }
}
