.news {
  position: relative;
  padding-top: 104px;
  padding-bottom: 81px;
  @include respond-max('tablet-small') {
    padding-top: 84px;
    padding-bottom: 50px;
  }
  @include respond-max('mobile') {
    padding-top: 64px;
    overflow: hidden;
    padding-bottom: 65px;
  }
  // &__slider-wrapper {
  //   @include respond-max('mobile') {
  //     margin-left: -24px;
  //     margin-right: -24px;
  //   }
  // }
  &__item {
    display: block;
    color: $black;
    text-decoration: none;
    outline: 0;
    // width: 100%;
    border: 1px solid #EBEBEB;
    padding: 31px 30px;
    margin-bottom: 32px;
    box-sizing: border-box;
    width: 100%;
    @include respond-max('mobile') {
      padding: 23px 23px;
      margin-bottom: 0;
    }
  }
  &__col {
      display: flex;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.34;
    letter-spacing: 0.01em;
    margin-top: 0;
    margin-bottom: 16px;
    @media screen and (max-width: 575px) {
      padding-right: 31px;
    }
  }
  &__description {
    font-size: 14px;
    line-height: 1.72;
    padding-right: 31px;
  }
  &__date {
    color: $grey;
    font-size: 12px;
    margin-top: 18px;
  }
  &__btn {
    display: block;
    min-width: 40px;
    width: 40px;
    height: 40px;
    background-color: #FAFAFA;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    user-select: none;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transition: 0.25s ease;
    margin: 0;
    padding: 0;
    &:before {
      content: "";
      display: none;
    }
    &:after {
      content: "";
      display: none;
    }
    .icon {
      fill: none;
      width: 10px;
      height: 18px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -9px;
      margin-left: -5px;
    }
  }
  &__slider {
    position: relative;
    padding-right: 1px;
  }
  &__btns {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -88px;
    margin-right: 1px;
    user-select: none;
    @include respond-max('tablet-small') {
      margin-top: -72px;
    }
    @media screen and (max-width: 575px) {
     display: none;
    }
  }
  &__btn-prev {
    margin-right: 8px;
  }
  .swiper-slide {
    height: auto;
    display: flex;
    // &:first-child {
    //   @media screen and (max-width: 575px) {
    //     width: 304px !important;
    //     .news__item {
    //       margin-left: 24px;
    //     }
    //   }

    // }
    @media screen and (max-width: 575px) {
      width: 280px !important;
    }
  }
  .swiper-container {
    @media screen and (max-width: 575px) {
      overflow: visible;
    }
  }
}


.news-slide {
  display: block;
  width: 100%;
  height: auto;
  padding-bottom: 62.5%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
