.awards {
  background-color: #FAFAFA;
  padding-top: 104px;
  padding-bottom: 104px;
  @include respond-max('tablet-small') {
    padding-top: 84px;
    padding-bottom: 84px;
  }
  @include respond-max('mobile') {
    padding-top: 48px;
    padding-bottom: 40px;
  }

  &__text {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.43;
    letter-spacing: 0.01em;
    color: $black;
    margin: 0;
    margin-bottom: 44px;
    @include respond-max('tablet') {
      font-size: 24px;
    }
    @include respond-max('tablet-small') {
      font-size: 20px;
    }
    @include respond-max('mobile') {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 36px;
    }
  }
  &__name {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 6px;
  }
  &__post {
    font-size: 14px;
    line-height: 1.5;
    color: $darkGrey;
  }
  &__reward {
    @include respond-max('mobile') {
      margin-bottom: 32px;
    }
    img {
      display: inline-block;
      width: 100%;
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
  &__hr-col {
    @include respond-max('mobile') {
      display: none;
    }
  }
  &__hr {
    height: 100%;
    position: relative;
    width: 1px;
    margin: 0 auto;
    background-color: #EBEBEB;
  }
}
