.privacy {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  @include respond-max('tablet') {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  hr {
    margin-bottom: 0;
    margin-top: 26px;
  }
  &__text {
    h2, h3, h4, h5, h6 {
      font-size: 2.2em;
      margin: 0;
      margin-bottom: 20px;
      margin-top: 30px;
      @include respond-max('tablet') {
        font-size: 1.8em;
        line-height: 1.5;
      }
      @include respond-max('mobile') {
        font-size: 1.4em;
        line-height: 1.5;
      }
    }
    line-height: 2;
    padding-top: 1px;
    // padding-bottom: 1px;
    padding-bottom: 30px;
    @include respond-max('tablet') {
      line-height: 1.7;
      padding-bottom: 1px;
    }
    p {
      margin: 0;
      margin-bottom: 16px;
      // @include respond-max('mobile') {
      //   margin-bottom: 20px;
      // }
    }
    a {
      color: inherit;
      text-decoration: underline;
    }
    b, strong {
      font-weight: 500;
    }
    ul {
      display: block;
      list-style-type: disc;
      margin: 0;
      margin-bottom: 24px;
        list-style-position: inside;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 26px;
      // @include respond-max('mobile') {
      //   margin-bottom: 20px;
      // }
      li {
        display: list-item;
        list-style-type: disc;
        margin: 0;
        margin-bottom: 15px;
        list-style-position: inside;
        // @include respond-max('mobile') {
        //   margin-bottom: 10px;
        // }
        &:last-child {
          margin-bottom: 0;
        }
        &::marker {
          color: #A5121A;
        }
      }
    }
    ol {
      display: block;
      list-style-type: decimal;
      margin: 0;
      margin-bottom: 24px;
      @include respond-max('mobile') {
        margin-bottom: 20px;
      }
      li {
        list-style-type: decimal;
        display: list-item;
        margin: 0;
        margin-bottom: 15px;
        list-style-position: inside;
        @include respond-max('mobile') {
          margin-bottom: 10px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__contacts {
    margin-bottom: 20px;
    margin-top: 40px;
    @include respond-max('mobile') {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
