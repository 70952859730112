.present {
  position: relative;
  padding-top: 64px;
  // padding-top: 94px;
  padding-bottom: 31px;
  @include respond-max('tablet-small') {
    padding-top: 54px;
  }
  @include respond-max('mobile') {
    padding-top: 24px;
    padding-bottom: 30px;
  }
  &__title {
    display: block;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.01em;
    margin: 0;
    margin-bottom: 40px;
    padding-right: 35px;
    @include respond-max('tablet') {
      font-size: 32px;
    }
    @include respond-max('mobile') {
      font-size: 24px;
      padding-right: 0;
      letter-spacing: 0;
      line-height: 1.35;
      margin-bottom: 33px;
    }
  }
  &__types-title {
    font-size: 16px;
    color: $darkGrey;
    margin-bottom: 39px;
    padding-top: 7px;
    @include respond-max('mobile') {
      line-height: 2;
      margin-bottom: 32px;
    }
    span {
      @include respond-max('mobile') {
        display: block;
      }
    }
  }
  &__type-item {
    display: flex;
    // align-items: center;
    align-items: flex-start;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 32px;
    @include respond-max('mobile') {
      line-height: 2;
      // margin-bottom: 29px;
    }
  }
  &__type-icon {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: auto;
    margin-right: 24px;
  }
  &__type-name {
    display: inline-block;
    vertical-align: middle;
    margin-top: 4px;
    @include respond-max('mobile') {
      margin-top: 0;
    }
  }


  &__progress {
    border-top: 1px solid rgba(235, 235, 235, 0.8);
    padding-top: 60px;
    padding-bottom: 77px;
    margin-top: 72px;

    @include respond-max('tablet') {
      padding-bottom: 37px;
    }
    @include respond-max('tablet-small') {
      padding-bottom: 10px;
      padding-top: 60px;
      margin-top: 50px;
    }
    @include respond-max('mobile') {
      padding-bottom: 10px;
      padding-top: 53px;
      margin-top: 8px;
    }
  }
  &__num {
    font-size: 64px;
    line-height: 1;
    letter-spacing: 0.01em;
    color: $black;
    word-spacing: -0.1em;
    white-space: nowrap;
    span {
      font-size: 0.64em;
      @include respond-max('mobile') {
        font-size: 0.5em;
      }
    }
    @include respond-max('tablet') {
      font-size: 48px;
    }
    @include respond-max('mobile') {
      font-size: 48px;
    }
  }
  &__progress-row {
    display: flex;
    margin: 0 -16px;
    flex-wrap: wrap;
  }
  &__progress-item {
    display: flex;
    @include respond-max('tablet-small') {
      display: block;
      text-align: center;
      margin-bottom: 40px;
    }
    @include respond-max('mobile') {
      margin-bottom: 37px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.11em;
    text-transform: uppercase;
    color: $darkGrey;
    padding-left: 23px;
    padding-top: 4px;

    @include respond-max('tablet') {
      font-size: 14px;
      padding-left: 20px;
      padding-top: 0;
    }
    @include respond-max('tablet-small') {
      padding-left: 0;
    }
    @include respond-max('mobile') {
      font-size: 16px;
      margin-top: 13px;
    }
    br {
      @include respond-max('tablet-small') {
        display: none;
      }
    }
  }

}
