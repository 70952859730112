.feedback {
  position: relative;
  &__title.sm-title {
    margin-bottom: 24px;
  }
  &__description {
    font-size: 16px;
    line-height: 2;
    color: $darkGrey;
    padding-right: 170px;
    margin-bottom: 28px;
    @include respond-max('tablet-small') {
      padding-right: 0;
    }
    @include respond-max('mobile') {
      margin-bottom: 17px;
    }
  }
  &__privacy {
    font-size: 12px;
    line-height: 1.34;
    margin-top: 20px;
    color: $grey;
    @include respond-max('mobile') {
      margin-top: 16px;
    }
    a {
      color: $blue;
    }
  }
}
