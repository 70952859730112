// $select2primary-hover: #CC9E5A;
$select2primary-hover: #F0F0F0;
$select2primary-color: #fff;
$select2primary-selected: #eff1f5;
// $select2secondary-color: #7fff00;
$select2secondary-color: #F0F0F0;
// $select2text-color: #001426;
$select2text-color:  #7C878E;

// .select2-container {
//   z-index: 1;
// }
.form-field {
  &.is-success {
    .form-select2 {
      .select2-selection__arrow {
        // display: none
      }
    }
  }
  &.is-error {
    .form-select2 {
      .select2-selection__arrow {
        // display: none
      }
    }
  }

  &.is-focus {
    .select2-container--default {
      .select2-selection--single {
        .select2-selection__placeholder {
          opacity: 1
        }
      }
    }
  }

  .select2-selection {
    &.select2-selection--single {
      z-index: 9999;
      position: relative;
    }
  }
  // &.fieldset-line--select {

  //   // .form-field--select {
  //     &.is-focus-line {
  //       .form-field.is-focus .form-placeholder{
  //         opacity: 0;
  //         transition: 0s 0s;

  //       }
  //     }
  //   // }
  // }
}
.fieldset-line--select{
  &.is-focus-line {
    .form-field.is-focus .form-placeholder{
      opacity: 0;
      transition: 0s 0s;

    }
  }
  .form-field .form-placeholder{
    // opacity: 0;
    transition: 0s 0s;
  }
}

.select2-selection {
  &.select2-selection--single {
    z-index: 9999;
    position: relative;
  }
}

.form-select2 {
  ::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .24)
  }

  .select2-selection--single {
    outline: none;
    border-radius: 0 !important;
    display: block;
    width: 100%;
    height: 43px;
    color: #001426;
    padding-right: 30px;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    border-radius: 0;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    // border-bottom: 2px solid #000000;
    // border-bottom: 1px solid #C1C6C8;
    border-bottom: 1px solid #C1C6C8 !important;
    transition: 300ms ease-in-out;

    @include respond-max('mobile') {
      height: 50px;
    }

    .select2-selection__rendered {
      padding: 0;
      font-size: inherit;
      // line-height: 48px;
      font-weight: 400;
      // line-height: normal;
      // height: 42px;
      line-height: 42px;
      height: auto;
      // line-height: 35px;
      // height: 42px;
      // border-bottom: 1px solid #C1C6C8;
      padding-left: 9px;
      padding-right: 9px;

      @include respond-max('mobile') {
        font-size: 16px;
        // line-height: 38px;
        line-height: 49px;
      }
    }

    .select2-selection__arrow {
      width: 10px;
      height: 8px;
      position: absolute;
      top: 50%;
      bottom: 0;
      right: 12px;
      margin: auto;
      // margin-top: -10px
      margin-top: -4px;
      background: url('../images/svg/select-arrow.svg') no-repeat;
      background-size: contain;
      // transition: transform 300ms ease-out;
      transition: 0s 0s;
      background-position: center;

      b {
        display: none
      }
    }
  }

  &.select2-container--focus {
    .select2-selection--multiple {
      border-color: #cacbcc
    }
  }

  &.select2-container--open {
    .select2-selection__arrow {
      transform: rotate(180deg)
    }
  }

  &.select2-container--disabled .select2-selection--single {
    background-color: #fff;

    .select2-selection__rendered {
      color: #A7A8AA
    }
  }

  .select2-dropdown {
    font-size: 16px;
    background: #fff;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    // transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 0, 0);

    @include respond-max('mobile') {
      font-size: 16px;
    }
  }

  .select2-results {
    & > .select2-results__options {
      max-height: 320px;
      padding: 8px 0;

      @include respond-max('mobile') {
        padding: 0;
      }
    }
  }


  .select2-results {
    display: block;
    position: relative;
    &:before {
        content: "";
        display: block;
        position: absolute;
        // box-shadow: 0px 2px 8px black;
        background: #FAFAFA;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        pointer-events: none;
    }

  }

  .select2-dropdown {
    &--below {
      .select2-results {
        &:before {
          // top: -27px;
          // top: -53px;
          top: -44px;
          @include respond-max('mobile') {
            top: -62px;
          }
        }
      }
    }
    &--above {
      .select2-results {
        &:before {
          // top: -27px;
          // bottom: -53px;
          bottom: -44px;
          @include respond-max('mobile') {
            bottom: -49px;
          }
        }
      }
    }
  }

  .select2-results__option {
    cursor: pointer;
    // padding: 6px 40px 6px 41px;
    padding: 11px 16px;
    padding-right: 40px;
    transition: 300ms ease-out;

    @include respond-max('mobile') {
      padding: 5px 15px;
      padding-right: 40px;
    }

    &--highlighted[aria-selected] {
      // color: #fff;
      color: #7C878E;
      background-color: $select2primary-hover;

    }

    // &[aria-selected=true] {
    //   // display: none
    //   // display: block
    // }

    &:empty {
      display: none;
    }

    &:hover {
      background-color: $select2primary-hover;
      color: #fff;

    }
  }
}

.fieldset-line.is-focus-line {
  .form-select2 {
    .select2-selection--single {
      border-bottom-color: transparent
    }
  }
}

.select2-container--default {
  .select2-results__option {
    &[aria-selected=true] {
      color: $select2text-color;
      // background-color: $select2primary-selected;
      // background-color: #ffffff;
      background-color: #F0F0F0;
    }
    &--highlighted[aria-selected] {
      color: #fff;
      background-color: $select2primary-hover;
      background-color: #F0F0F0;
      color: #7C878E;
    }
  }

  .select2-selection--single {
    .select2-selection__placeholder {
      display: block;
      padding: 0;
      font-size: inherit;
      // line-height: 48px;
      font-weight: 400;
      color: #001426;
      transition: 0.2s 0s cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      @include respond-max('mobile') {
        // line-height: 38px;
        line-height: 49px;
      }
    }
  }
}

.select2-container--disabled,
.select2-container--disabled + .form-placeholder {
  opacity: 0.5;
}
