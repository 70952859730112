.contacts {
  position: relative;
  background-color: #FAFAFA;
  // padding-top: 103px;
  padding-top: 1px;
  padding-bottom: 81px;
  @include respond-max('tablet-small') {
    padding-bottom: 61px;
  }
  @include respond-max('mobile') {
    padding-bottom: 34px;
  }
  &__form {
    padding-top: 102px;
    @include respond-max('tablet-small') {
      padding-top: 82px;
    }
    @include respond-max('mobile') {
      padding-top: 39px;
    }
  }
  &__info {
    // padding-top: 8px;
    padding-top: 110px;
    padding-bottom: 1px;
    @include respond-max('tablet-small') {
      padding-top: 90px;
    }
    @include respond-max('mobile') {
      padding-top: 64px;
    }
  }
  &__title {
    font-size: 24px;
    line-height: 1.375;
    font-weight: 600;
    letter-spacing: 0.012em;
    margin: 0;
    margin-bottom: 36px;
    color: $black;
  }
  &__item {
    font-size: 14px;
    line-height: 1.72;
    margin-bottom: 29px;
  }
  &__item-name {
    font-size: 10px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $grey;
    margin-bottom: 8px;
  }
  &__item-link {
    color: $blue;
    margin-top: 12px;
    display: inline-block;
    text-decoration: none;
  }
  &__item-value {
    a {
      font-size: 16px;
      display: inline-block;
      line-height: 2;
    }
  }
}
