html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

$black: #001426;
$blue: #005DAD;
$lightGrey: #C1C6C8;
$darkGrey: #7C878E;
$grey: #A2AAAD;

body {
  position: relative;
  font-size: 14px;
  font-family: 'Graphik LCG', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  color: #001426;
}

*,
:before,
:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  -webkit-locale: auto;
}

svg {
  pointer-events: none;
}

input,
textarea,
button {
  border: none;
  outline: none;
  border-radius: 0;
}

input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
input[type='number'],
textarea,
button {
  -webkit-appearance: none;
}


input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
	visibility: hidden;
	pointer-events: none;
	position: absolute;
	right: 0;
}

input::-ms-reveal,
input::-ms-clear {
	width: 0;
	height: 0;
}

fieldset {
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: normal;
  color: inherit;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

li {
  list-style: none;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.sm-title {
  font-size: 32px;
  line-height: 1.375;
  font-weight: 600;
  letter-spacing: 0.01em;
  margin: 0;
  margin-bottom: 48px;
  color: $black;
  @include respond-max('tablet-small') {
    margin-bottom: 32px;
  }
}


.fieldset-line {
  display: block;
  margin-bottom: 20px;
  padding-top: 10px;
  @include respond-max('mobile') {
    padding-top: 14px;
    background-color: transparent;
    margin-bottom: 24px;
    &.is-focus-line {
      background-color: #fff;
      border-radius: 4px 4px 0px 0px;
    }
  }
}
.form-placeholder {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  color: $darkGrey;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  border-radius: 0;
  box-sizing: border-box;
  background-color: transparent;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  position: absolute;
  top: 0;
  left: 9px;
  pointer-events: none;

  // @media #{$mobile} {
  //   font-size: 16px;
  // }
  @include respond-max('mobile') {
    font-size: 16px;
    height: 50px;
  }
  sup {
    top: -0.6em;
    font-size: 50%;
    left: 1px;
  }
}

.form-field {
  position: relative;

  &.is-focus {
    .form-placeholder {
      font-size: 12px;
      transform: translate3d(0, -20px, 0);
      @include respond-max('mobile') {
        // transform: translate3d(0, -16px, 0);
        transform: translate3d(0, -23px, 0);
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 13px;
    right: 0;
    width: 24px;
    height: 24px;
    // background: url('../images/svg/warning.svg') no-repeat;
    background-size: 100%;
    opacity: 0;
    pointer-events: none;

    @include respond-max('mobile') {
      top: 8px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 13px;
    right: 0;
    width: 24px;
    height: 24px;
    // background: url('../images/svg/success.svg') no-repeat;
    background-size: 100%;
    opacity: 0;
    pointer-events: none;

    @include respond-max('mobile') {
      top: 8px;
    }
  }
}

.form-input {
  display: block;
  width: 100%;
  height: 43px;
  color: $black;
  padding-left: 9px;
  padding-right: 30px;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  border-radius: 0;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $lightGrey;
  transition: 300ms ease-in-out;
  outline: 0;
  color: $black;
  -webkit-appearance: none;
  appearance: none;

  // @include respond-max('mobile') {
  //   font-size: 16px;
  //   height: 40px;
  // }
  @include respond-max('mobile') {
    height: 50px;
  }
}

.fieldset-button {
  margin-top: 40px;
}

.form-button {
  width: 100%;
}

label.error {
  position: absolute;
  top: 100%;
  margin-top: 8px;
  font-size: 10px;
}
span.label-error {
  position: absolute;
  top: 100%;
  margin-top: 8px;
  font-size: 10px;
}

.is-error {
  &.form-field {
    &:before {
      opacity: 1;
    }
  }

  .form-input {
    border-color: #EF3124;
  }

  .form-placeholder {
    color: #EF3124;
  }
  .form-select {
    background-image: none;
  }
  span.label-error {
    color: #EF3124;
  }
}

.is-success {
  &.form-field {
    &:after {
      opacity: 1;
    }
  }
  .form-select {
    background-image: none;
  }
}

.form-input:disabled,
.form-input:disabled + .form-placeholder {
  opacity: 0.5;
}
