.map-container,
.map-locations,
.map-ymaps {
	width: 100%;
	height: 100%;
}
#map,
.map-container,
.map-locations,
.map-ymaps {
	// width: 100%;
	// height: 100%;
	// background-color: $grey2;

	[class*="ymaps-2"][class*="-ground-pane"] {
		filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
		-webkit-filter: grayscale(100%);
  }

	// [class*="-balloon"]{
  //   max-width: 296px;
  // }
	[class*="-balloon__content"]{
		// padding: 0 !important
		padding: 0 !important;
		font-family: inherit !important;
    margin: 0 !important;
  }
	.wrap-pin{
		[class*="-balloon__content"]{
			padding: 0 !important;
			margin: 0 !important;
    }
		[class*="-balloon__close"]{
			display: none !important;
    }
  }
  [class*="-balloon__close-button"]{
    // width: 10px;
    // height: 10px;
    width: 20px;
    height: 20px;
    opacity: .8;
    background-size: 50% auto;
    position: absolute;
    // top: 19px;
    // right: 19px;
    top: 14px;
    right: 14px;
  }
	[class*="-controls__toolbar"]{
		opacity: 0;
		cursor: initial;
  }
	// [class*="-balloon__tail"]{
  //   transform: none;
  //   display: block;
  //   position: relative;
  //   left: 0;
  //   width: 17px;
  //   height: 17px;
  //   margin-bottom: -17px;
  //   &:before {
  //     content: "";
  //   }
  // }
}
.maps {
  position: relative;
  @include respond-max('mobile') {
    padding-top: 50px;
  }
  &__box {
    position: relative;
    // height: 800px;
    height: 600px;
    background-color: #C1C6C8;
    @include respond-max('tablet') {
      height: 600px;
    }
    @include respond-max('mobile') {
      height: 480px;
    }
  }
  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.baloon-card {
  box-sizing: border-box;
  display: block;
  position: relative;
  padding: 35px 33px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.34;
  color: $darkGrey;
  max-width: 296px;
  user-select: none;
  &__title {
    font-size: 14px;
    color: $black;
    margin-bottom: 19px;
  }
  &__description {
    p {
      margin: 0;
      margin-bottom: 8px;
    }
  }
  &__link-wrapper {
    margin-top: 22px;
    margin-bottom: 2px;
  }
  &__link {
    font-weight: normal;
    font-size: 16px;
    color: $blue;
  }
}
