// .container {
//   position: relative;
//   width: 100%;
//   max-width: 1192px;
//   margin: 0 auto;
//   padding: 0 16px;
// }
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  @include respond-max('mobile') {
    padding-right: 24px;
    padding-left: 24px;
  }
}
