.modal {
  overflow: auto;
  position: absolute;
  top: 16px;
  bottom: 16px;
  left: 0;
  right: 16px;
  z-index: 100;
  color: $black;

  .mfp-close {
    display: none;
  }

    &-close {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      top: 56px;
      right: 56px;
      z-index: 2;
      background-color: rgba(0, 0, 0, .5);
      border-radius: 50%;
      transition: background-color .2s linear;

      @include respond-max('tablet') {
        right: 35px;
        top: 35px;
      }
      @include respond-max('mobile') {
          right: 24px;
          top: 24px;
      }

    svg {
      width: 10px;
      height: 10px;
      fill: #fff;
    }
  }
}

.mfp-bg {
  z-index: 101;
  opacity: 0.5;
  display: none;
}

[data-mfp-src] {
  outline: none;
}

.mfp-container {
  padding: 0;
}

.mfp-content {
  padding: 0;
  width: 100%;
  height: 100%;
}

.mfp-wrap {
  z-index: 99;
  top: 0 !important;
  position: fixed !important;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0;
  overflow: hidden;

  &.is-hidden {
    overflow: hidden !important;
  }

  &.mfp-ready {
    z-index: 151;
  }
}

.mfp-open {
  .modal-filter__apartment {
    opacity: 1;
    pointer-events: auto;
  }
  .mfp-bg {
    display: block;
  }
}
.modal-news-block {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  // padding: 90px 100px 90px 70px;
  line-height: 1.15;
}

.modal-news{
  &.modal {
    // left: 50%;
    left: auto;
    width: 760px;
    background-color: #fff;
    // @include respond-max('tablet') {
    //   left: 0;
    // }
    @include respond-max('tablet') {
      width: 736px;
    }
    @include respond-max('mobile') {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: auto;
    }
  }
  .modal-close {
    // background-color: #F7F7F7;
    background-color: #FAFAFA;

    border: 1px solid #C1C6C8;
    // box-sizing: border-box;
    // border-radius: 24px;

    transition: 0.3s ease-out;
    position: fixed;
    svg {
      fill: #000;
    }
    &:hover {
      background-color: #E9E9E9
    }
    // @include respond-max('mobile') {
    //   // @include respond-max('mobile')
    //       width: 50px;
    //       height: 50px;
    //       top: 10px;
    //       right: 10px;
    // }
  }

}

.mfp-wrap {
  &.modal-move-effect {

    .modal-news.modal {
      transform: translateX(120%);
      transform-origin: center right;
      transition: 0.2s 0s ease-in;
    }

    &:not(.effect_hide){
      &.mfp-ready {
        .modal-news.modal {
          transform: none;
          transition: 0.5s 0.1s ease-in
        }
      }
    }
  }
}

.detail-news {
  // padding: 47px 65px 0 65px;
  padding-top: 120px;
  font-size: 16px;
  color: #000;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @include respond-max('mobile') {
      //padding: 31px 24px 0px 24px;
      padding-top: 86px;
  }
  &__head {
    padding-bottom: 16px;
    @include respond-max('mobile') {
      padding-bottom: 1px;
    }
  }
  &__row {
    margin-bottom: 56px;
    @include respond-max('mobile') {
      margin-bottom: 39px;
    }
  }
  &__title {
    font-size: 40px;
    margin-bottom: 41px;
    @include respond-max('mobile') {
        font-size: 24px;
        margin-bottom: 32px;
        line-height: 1.37;
    }
    // @include respond-max('mobile') {
    //   margin-bottom: 34px;
    //   &.title-sm {
    //     line-height: 1.2;
    //     @include respond-max('mobile') {
    //       line-height: 1.35;
    //     }
    //   }
    // }
  }
  &__slider-minus-box {
    //margin: 0 -50px;
    @include respond-max('mobile') {
      //margin: 0 -24px;
    }
  }
  &__slider-block {
    margin-bottom: 93px;
    position: relative;
    @include respond-max('mobile') {
      margin-bottom: 89px;
    }
  }
  &__date {
    display: inline-block;
    // color: #A7A8AA;
    color: $lightGrey;
  }
  &__type {
    display: inline-block;
    margin-right: 16px;
  }
  &__preview-text {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 1.5
    b, strong {
      font-weight: 500
    }
    // @include respond-max('mobile') {
    //   font-size: 16px;
    // }
  }
  &__text {
    line-height: 2;
    padding-top: 1px;
    // padding-bottom: 1px;
    padding-bottom: 32px;
    @include respond-max('mobile') {
        padding-bottom: 16px;
    }
    img {
      margin-bottom: 48px;
      margin-top: 31px;
      display: inline-block;
      max-width: 100%;
      height: auto;
      // @include respond-max('mobile') {
      //   margin-top: 2px;
      //   margin-bottom: 20px;
      // }
    }
    p {
      margin: 0;
      margin-bottom: 32px;
      // @include respond-max('mobile') {
      //   margin-bottom: 20px;
      // }
    }
    a {
      color: inherit;
      text-decoration: underline;
    }
    b, strong {
      font-weight: 500;
    }
    ul {
      display: block;
      list-style-type: disc;
      margin: 0;
      margin-bottom: 24px;
      // @include respond-max('mobile') {
      //   margin-bottom: 20px;
      // }
      li {
        display: list-item;
        list-style-type: disc;
        margin: 0;
        margin-bottom: 15px;
        list-style-position: inside;
        // @include respond-max('mobile') {
        //   margin-bottom: 10px;
        // }
        &:last-child {
          margin-bottom: 0;
        }
        &::marker {
          color: #A5121A;
        }
      }
    }
    ol {
      display: block;
      list-style-type: decimal;
      margin: 0;
      margin-bottom: 24px;
      @include respond-max('mobile') {
        margin-bottom: 20px;
      }
      li {
        list-style-type: decimal;
        display: list-item;
        margin: 0;
        margin-bottom: 15px;
        list-style-position: inside;
        @include respond-max('mobile') {
          margin-bottom: 10px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__advantage-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -32px;
    @include respond-max('mobile') {
      margin: 0 -16px;
    }
  }
  &__advantage-col {
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 32px;
    @include respond-max('mobile') {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 16px;
    }
  }
  &__footer {
      display: block;
      // width: 100%;
      padding: 1px 0;
      position: relative;
      // border-top: 1px solid #000;
      // padding-top: 76px;
        padding-top: 32px;
        padding-bottom: 32px;
        width: auto;
        margin-left: -65px;
        margin-right: -65px;
        background-color: #FAFAFA;
        padding-left: 65px;
        padding-right: 65px;
        border: 0;
        margin-top: auto;
      @include respond-max('mobile') {
        padding-top: 24px;
        padding-bottom: 24px;
        width: auto;
        margin-left: -24px;
        margin-right: -24px;
        background-color: #FAFAFA;
        padding-left: 24px;
        padding-right: 24px;
        border: 0;
        margin-top: auto;
      }
  }
  // &__sm-next-text {
  //   font-size: 12px;
  //   color: #A7A8AA;
  //   margin-bottom: 20px;
  //   @include respond-max('mobile') {
  //     margin-bottom: 12px;
  //   }
  // }
  // &__next {
  //   font-size: 20px;
  //   color: #000;
  //   text-decoration: none;
  //   outline: 0;
  //   max-width: 280px;
  //   display: inline-block;
  //   line-height: 1.5;
  //   @include respond-max('tablet'){
  //       max-width: 320px;
  //   }
  //   @include respond-max('mobile') {
  //     font-size: 16px;
  //     max-width: 100%;
  //   }
  // }

  .home-scr {
    &__btn.home-scr__prev-btn {
      left: 15px;
    }
    &__btn.home-scr__next-btn {
      right: 15px;
    }
    &__pagination.swiper-pagination {
      bottom: -29px;
      .swiper-pagination-bullet {
        background-color: #001426;
      }
    }
  }
}

.modal-footer {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    @include respond-max('mobile') {
      display: block;
    }
  }
  &__col {
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 12px;
    padding-right: 12px;
    @include respond-max('mobile') {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &__button.button--white {
    @include respond-max('mobile') {
      margin-top: 24px;
    }
  }
  .button.button_text-left {
    height: 48px;
  }

}

.detail-advant {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.75;
  margin-bottom: 48px;
  &__icon {
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 23px;
  }
  &__name {
    padding-top: 8px;
  }
}
