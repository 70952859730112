.home-scr {
  position: relative;
  // background-color: #C1C6C8;
  background-color: #FAFAFA;
  .swiper-slide {
    height: auto;
  }
  &__bottom-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 40px 40px 0px 0px;
    height: 40px;
    z-index: 1;
    @include respond-max('mobile') {
      border-radius: 20px 20px 0px 0px;
    }
  }
  &__pagination.swiper-pagination {
    bottom: 60px;
    left: 0;
    width: 100%;
    z-index: 2;
    @include respond-max('mobile') {
      bottom: 61px;
    }
    .swiper-pagination-bullet {
      margin: 0 12px;
      width: 8px;
      height: 8px;
      background-color: #fff;
      opacity: .4;
      transform: none;
      &.swiper-pagination-bullet-active {
        opacity: 1;
        transform: scale3d(1.25,1.25,1.25);
      }
    }
  }
  &__btn {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    position: absolute;
    cursor: pointer;
    user-select: none;
    opacity: 1;
    top: 50%;
    margin: 0;
    margin-top: -24px;
    &:before {
      content: "";
      display: none;
    }
    &:after {
      content: "";
      display: none;
    }
    &:hover {
      .home-scr__btn-icon {
        opacity: 0;
      }
      .home-scr__btn-icon-hover {
        opacity: 1;
      }
    }
    &.home-scr__prev-btn {
      left: 24px;
      right: auto;
    }
    &.home-scr__next-btn {
      left: auto;
      right: 24px;
    }
    .swiper-button-disabled {
      opacity: 0.5;
    }
    @include respond-max('mobile') {
      display: none;
    }
  }
  &__btn-icon {
    display: block;
    width: 48px;
    height: 48px;
    opacity: 1;
    transition: 0.25s ease;
  }
  &__btn-icon-hover {
    display: block;
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.25s ease;
  }
}
.home-slide {
  display: block;
  position: relative;
  padding: 10px 0;
  box-sizing: border-box;
  background-color: #C1C6C8;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  color: #FFFFFF;
  @include respond-max('tablet-small') {
    // min-height: 134vw;
    min-height: 480px;
  }
  @include respond-max('mobile') {
    // min-height: 134vw;
    min-height: 480px;
  }
  &__size-wrapper {
    z-index: 2;
    position: relative;
    padding-top: 167px;
    padding-bottom: 150px;
    @include respond-max('mobile') {
      padding-top: 95px;
      padding-bottom: 100px;
    }
  }
  &__title {
    font-size: 72px;
    line-height: 1.12;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    @include respond-max('tablet') {
      font-size: 52px;
    }
    @include respond-max('tablet-small') {
      font-size: 52px;
      // font-size: 32px;
    }
    @include respond-max('mobile') {
      font-size: 32px;
      line-height: 1.4;
    }
  }
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .35);
      z-index: 1;
    }
  }
}

